<template>
  <v-row align="center" justify="center" no-gutters class="overflow-x-auto">
    <vue-headful
      title="Automotriz Petersen - Venta de Autos"
      description="Venta de vehículos nuevos y usados, vehículos con garantía, financiamos tu vehículo con pie, bonos de financiamiento, recibimos tu vehículo en parte de pago."
    />
    <v-col cols="12">
      <v-row
        align="center"
        justify="center"
        no-gutters
        style="background-color: #0434ac"
      >
        <titulos-fondos text="Experiencia - Transparencia - Respaldo" />
      </v-row>
    </v-col>
    <v-col cols="12">
      <img :src="home" class="my-img-class" alt="petersen" />
    </v-col>
    <v-col cols="12" class="mt-6">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="10">
          <h1 class="title--text text-center">
            Vehículos inspeccionados y garantizados con asistencia en ruta
            gratuita
          </h1>
        </v-col>
        <v-col cols="10">
          <h1 class="title--text text-center">
            ¡Transparencia y asesoría, cotiza y compara!
          </h1>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <slider-vehicles />
    </v-col>
    <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-12' : ''">
      <v-row align="center" justify="center" no-gutters>
        <v-btn color="info" class="text-capitalize subtitle-1" @click="goStock">
          Ver Autos Disponibles
        </v-btn>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row align="center" justify="center" no-gutters class="mt-12">
        <v-col cols="12" md="11" xl="11" lg="11">
          <servicios />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="mt-12">
      <titulos-fondos text="Compramos tu Auto" />
    </v-col>
    <v-col cols="12">
      <v-row align="center" justify="center" no-gutters id="compramos-tu-auto">
        <compramos-auto />
      </v-row>
    </v-col>
    <v-col cols="12" class="mt-12">
      <titulos-fondos text="Nuestro Equipo" />
    </v-col>
    <v-col cols="12" class="pa-0 my-12">
      <v-container>
        <trabajadores />
      </v-container>
    </v-col>
    <v-col cols="12">
      <hablemos-personas></hablemos-personas>
    </v-col>
    <v-col cols="12">
      <v-row align="center" justify="center" no-gutters class="mt-12">
        <iframe
          :width="
            $vuetify.breakpoint.mdAndUp
              ? $vuetify.breakpoint.xlOnly
                ? 1440
                : 1280
              : 350
          "
          height="330"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=PETERSEN%20-%20Avenida%20Vitacura,%20Vitacura,%20Chile&t=&z=13&ie=UTF8&iwloc=&output=embed"
        >
        </iframe>
      </v-row>
    </v-col>
    <v-row justify="center" no-gutters>
      <v-col cols="12" :xs="12" :sm="12" :md="6" :lg="6" :xl="4" class="my-3">
        <v-row
          align="center"
          :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'space-between'"
          no-gutters
        >
          <policy />
          <term />
        </v-row>
      </v-col>
    </v-row>
    <v-btn fab fixed bottom left color="green" @click="goToWhastapp">
      <v-icon large color="white">mdi-whatsapp</v-icon>
    </v-btn>
    <v-snackbar v-model="snackbar" color="info">
      <v-row align="center" justify="center" no-gutters>
        Mensaje Enviado
      </v-row>
    </v-snackbar>
  </v-row>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import SliderVehicles from '@/components/sliderVehicles.vue';
import Servicios from '@/components/vehiculo/servicios.vue';
import CompramosAuto from '@/components/compramosAuto.vue';
import Trabajadores from '@/components/trabajadores/trabajadores.vue';
import HablemosPersonas from '@/components/hablemosPersonas.vue';
import TitulosFondos from '@/components/titulosFondos.vue';
import Policy from '@/components/policy.vue';
import Term from '@/components/term.vue';

export default {
  components: {
    Term,
    Policy,
    TitulosFondos,
    HablemosPersonas,
    Trabajadores,
    CompramosAuto,
    Servicios,
    SliderVehicles,
  },

  data: () => ({
    showScrollButton: false,
    correo: '',
    form: false,
    dialog: false,
    snackbar: false,
    home: require('@/assets/home images/img.png'),
  }),
  computed: {
    ...mapState(['activeMostrar', 'loading', 'scroll', 'correoSend']),
  },
  watch: {
    snackbar(val) {
      if (!val) this.SET_CORREOSEND(val);
    },
    correoSend(val) {
      this.snackbar = val;
    },
    scroll: {
      immediate: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.goToCompramosTuAuto();
          }, 1000);
        }
        this.SET_SCROLL(false);
      },
    },
    dialog(val) {
      this.SET_ACTIVEMOSTRAR(val);
    },
  },
  methods: {
    ...mapMutations(['SET_ACTIVEMOSTRAR', 'SET_SCROLL', 'SET_CORREOSEND']),
    ...mapActions(['FIND_CARS']),
    goToCompramosTuAuto() {
      document
        .getElementById('compramos-tu-auto')
        .scrollIntoView({behavior: 'smooth'});
    },
    goTo() {
      let url =
        'https://www.google.com/maps/search/?api=1&query=automotriz%20petersen';

      window.open(url, '_blank');
    },
    contract() {
      window.dispatchEvent(new CustomEvent('chick', {detail: {chick: true}}));
    },
    gotoSelectedVendor() {
      window.dispatchEvent(new CustomEvent('top', {detail: {chick: true}}));
    },
    goToWhastapp() {
      window.open(
        'https://wa.me/56944436444?text=Hola Petersen! Estaba viendo su página web y tengo algunas consultas',
        '_blank'
      );
    },
    goStock() {
      this.$router.push({name: 'Stock'});
    },
  },
};
</script>
<style>
.gmap_canvas {
  overflow: hidden;
  background: none !important;
}

.my-img-class {
  width: 100%;
  height: 600px;
  object-fit: cover;
  object-position: top;
}
</style>
