<template>
  <base-dialog
    :title="`${vehiculo.datos.Marca} ${vehiculo.datos.Modelo} ${vehiculo.datos.Version} ${vehiculo.datos.Ano}`"
    :show-title-separator="true"
    dynamic-height
    :fullscreen="true"
    :open="open"
  >
    <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex flex-row">
      <div>
        <images-vehicle :vehiculo="vehiculo" />
      </div>
      <div>
        <vehiculo-info :vehiculo="vehiculo" />
      </div>
    </div>
    <div v-else>
      <v-col cols="12">
        <slider-cars
          :item="vehiculo"
          size-vehicle="width: 845"
          size-item="width: 140px"
          size-image="140px"
        ></slider-cars>
      </v-col>
      <v-col cols="12">
        <vehiculo-info :vehiculo="vehiculo" />
      </v-col>
    </div>
  </base-dialog>
</template>

<script>
import BaseDialog from '@/components/baseDialog';
import {existFoto} from '@/utils/cliente';
import ImagesVehicle from '@/components/vehiculo/imagesVehicle';
import VehiculoInfo from '@/components/vehiculo/vehiculoInfo';
import SliderCars from '@/components/sliderCars';

export default {
  name: 'vehiculoDetalle',
  components: {VehiculoInfo, ImagesVehicle, BaseDialog, SliderCars},
  props: {
    vehiculo: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    existFoto,
  },
};
</script>
