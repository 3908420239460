<template>
  <v-row align="center" justify="center" no-gutters>
    <trabajador
        v-for="trabajador of equipo_ventas"
        :key="trabajador.id"
        class="mb-12"
        :trabajador="trabajador"
    >
    </trabajador>
  </v-row>
</template>

<script>
import {mapState} from 'vuex';
import Trabajador from '@/components/trabajadores/trabajador';

export default {
  name: 'trabajadores',
  components: {Trabajador},
  computed: {
    ...mapState(['equipo_ventas']),
  },
};
</script>

<style scoped></style>
