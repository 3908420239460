import { render, staticRenderFns } from "./videoComponent.vue?vue&type=template&id=2c607a87&scoped=true&"
import script from "./videoComponent.vue?vue&type=script&lang=js&"
export * from "./videoComponent.vue?vue&type=script&lang=js&"
import style0 from "./videoComponent.vue?vue&type=style&index=0&id=2c607a87&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c607a87",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VFabTransition,VIcon,VImg,VProgressCircular,VSlider})
