<template>
  <div class="pa-1" :style="{'max-width': fixWidth, width: fixWidth}">
    <div :style="{'max-height': fixHeight, overflow: 'auto'}">
      <v-row align="center" justify="center" no-gutters>
        <div
          v-if="!isVid"
          :style="{
            'max-width': fixWidth2,
            width: '100%',
          }"
          class="image-container"
        >
          <v-img
            :key="validateImageFull(images[selected])"
            v-show="!magnify && images.length !== 0"
            :src="validateImageFull(images[selected])"
            :lazy-src="validateImageSmall(images[selected])"
            contain
            class="contained-image mb-1"
          />
          <zoom-on-hover
            :key="validateImageLarge(images[selected]).url"
            v-show="magnify && images.length !== 0"
            :img-normal="validateImageFull(images[selected])"
            :img-zoom="validateImageFull(images[selected])"
            :scale="1.5"
          ></zoom-on-hover>
        </div>
        <div
          v-else
          :style="{
            'max-width': fixWidth2,
            width: fixWidth2,
          }"
        >
          <video-component
            :key="images[selected].url"
            :source="images[selected]"
          ></video-component>
        </div>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="10">
          <v-row align="center" justify="space-between" no-gutters>
            <v-btn
              :disabled="selected === 0"
              fab
              color="info"
              small
              @click="moveImage('remove')"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn
              v-if="!isVid"
              fab
              :color="magnify ? 'info' : ''"
              small
              @click="magnify = !magnify"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              fab
              :disabled="selected === images.length - 1"
              color="info"
              small
              @click="moveImage('add')"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center" justify="space-between" no-gutters class="mt-1">
        <v-col
          v-for="(image, index) of images"
          :key="index"
          cols="6"
          class="pa-1"
        >
          <v-img
            v-if="!image.url.includes('mp4')"
            :src="validateImageLarge(image)"
            :lazy-src="validateImageSmall(image)"
            class="align-start text-start"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="info"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-chip
              v-if="index === 1"
              :color="vehicleStatus.color"
              small
              class="white--text mt-1 ml-1"
              >{{ vehicleStatus.status }}
            </v-chip>
          </v-img>
          <v-img
            v-else
            :src="validateImageLarge(images[3])"
            :lazy-src="validateImageSmall(images[3])"
            class="align-start text-start"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="info"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-chip color="info" small class="white--text mt-1 ml-1"
              >Video
            </v-chip>
          </v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {
  validateImageLarge,
  validateImageSmall,
  validateImageFull,
  imagesFixes,
} from '@/utils/vehiculo';
import {mapMutations} from 'vuex';
import VideoComponent from '@/components/vehiculo/videoComponent.vue';

export default {
  name: 'imagesVehicle',
  components: {VideoComponent},
  props: {
    vehiculo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selected: 0,
    magnify: false,
    video: null,
  }),
  computed: {
    fixWidth() {
      return `${this.$vuetify.breakpoint.width / 1.5}px`;
    },
    fixWidth2() {
      return `${this.$vuetify.breakpoint.width}px`;
    },
    fixHeight() {
      return `${this.$vuetify.breakpoint.height - 50}px`;
    },
    photos() {
      const {vehiculo} = this;
      return this.imagesFixes(vehiculo.datos);
    },
    images() {
      const {photos} = this;

      return photos.filter((_) => _);
    },
    isVid() {
      const {selected, images} = this;
      if (!images || !images.length) return null;

      return images[selected] && images[selected].url.includes('mp4');
    },
    vehicleStatus() {
      const {vehiculo} = this;

      const color = this.getColor(vehiculo.datos.status);

      return {
        status: vehiculo.datos.status,
        color,
      };
    },
  },
  mounted() {
    this.SET_VEHICLE_DETAIL(null);
  },
  methods: {
    ...mapMutations(['SET_VEHICLE_DETAIL']),
    moveImage(action) {
      if (action === 'add') {
        if (this.selected <= this.imagesFixes(this.vehiculo.datos).length - 1) {
          this.selected += 1;
        }
      } else {
        if (this.selected >= 1) {
          this.selected -= 1;
        }
      }
    },
    validateImageLarge,
    validateImageSmall,
    validateImageFull,
    imagesFixes,
    getColor(icon) {
      if (icon.toLowerCase().includes('reservado')) {
        return 'purple';
      }

      if (icon.toLowerCase().includes('venta')) {
        return 'info';
      }

      return 'green';
    },
  },
};
</script>
<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contained-image {
  max-width: 100%;
  max-height: 100%;
}
</style>
