/* eslint-disable */
/**
 * Function to close a modal, it detects if the route was access by directly
 * (Opened by a link click or typing the url) in this case,
 * the directAccess flag is set to false and
 * the user is redirected to the default route otherwise,
 * the user was already in the app and he will go back to the last page
 * @return {Promise<void>}
 */
export async function closeModal() {
  if (this.$route.meta.directAccess) {
    this.$route.meta.directAccess = false;
    await this.$router.push({name: 'petersen'});
  } else {
    await this.$router.back();
  }
  window.dispatchEvent(new CustomEvent('closeModal'));
}

/**
 * Computed property to get the max height allowed
 * for the modals depending on the screen resolution
 * @return {String} Max height for modal
 */
export function maxHeightModal() {
  return this.dynamicHeight
    ? 'unset'
    : this.$vuetify.breakpoint.mdAndDown
    ? '100vh'
    : '85vh';
}

/**
 * Computed property to get the max width allowed
 * for the modals depending on the screen resolution
 * @return {String} Max height for modal
 */
export function maxWidthModal() {
  return this.$vuetify.breakpoint.mdAndDown ? '100vw' : '80vw';
}

export const MODAL_HEIGHTS = {
  TITLE: 81,
  ACTIONS: 80,
  TABS: 50,
};
