<template>
  <v-row align="center" justify="space-between" no-gutters>
    <v-col cols="12" md="5" xl="5" lg="5">
      <v-row
        align="center"
        :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
        no-gutters
      >
        <h1 class="info--text heading-1 mb-12">Servicios</h1>
      </v-row>
      <v-col cols="12" v-for="(data, index) of info" :key="index">
        <v-row
          align="center"
          :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
          no-gutters
        >
          <v-col cols="5" md="2" xl="2" lg="2">
            <v-row align="center" justify="center" no-gutters>
              <div
                :style="$vuetify.breakpoint.xlOnly ? xlOnly : lgAndDown"
                class="mb-4"
              >
                <v-img
                  :src="data.path"
                  :width="resizeIconWidth()"
                  :max-width="resizeIconWidth()"
                  :height="resizeIconHeight()"
                  :max-height="resizeIconHeight()"
                  contain
                ></v-img>
              </div>
            </v-row>
          </v-col>
          <v-col cols="8">
            <p
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'subtitle-1 info--text text-uppercase font-weight-medium text-center'
                  : 'subtitle-1 info--text text-uppercase font-weight-medium'
              "
            >
              {{ data.titulo }}
            </p>
            <p
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'subtitle-2 info--text font-weight-regular text-center'
                  : 'subtitle-2 info--text font-weight-regular'
              "
            >
              {{ data.subtitulo }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
    <v-col cols="12" md="6" xl="6" lg="6">
      <v-img alt="servicios" :src="photo" contain></v-img>
    </v-col>
  </v-row>
</template>

<script>
import {resizeIconHeight, resizeIconWidth} from '@/utils/general';
import {mapState} from 'vuex';

export default {
  name: 'servicios',
  data: () => ({
    lgAndDown: 'width: 50px; height: 50px',
    xlOnly: 'width: 70; height: 70px',
    photo: '',
    info: [
      {
        titulo: 'Compramos tu auto',
        subtitulo: 'Te pagamos al instante.',
        path: require('@/assets/logos/servicios/Icono Cartera.png'),
      },
      {
        titulo: 'Variedad en stock',
        subtitulo: 'Vehiculos Garantizados - Historial a la vista.',
        path: require('@/assets/logos/servicios/Icono Auto.png'),
      },
      {
        titulo: 'Financiamiento inmediato',
        subtitulo: 'Aprobado en 45 minutos',
        path: require('@/assets/logos/servicios/Logo web compramos 1.png'),
      },
      {
        titulo: 'Seguro automotriz',
        subtitulo: 'Protege tu inversión',
        path: require('@/assets/logos/servicios/Icono Auto protege.png'),
      },
    ],
  }),
  methods: {
    resizeIconHeight,
    resizeIconWidth,
  },
  computed: {
    ...mapState(['photos']),
  },
  watch: {
    photos: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          const pos = Math.floor(Math.random() * val.length);
          this.photo = val[pos].url;
        }
      },
    },
  },
};
</script>

<style scoped></style>
