<template>
  <v-dialog
    :value="open"
    :fullscreen="$vuetify.breakpoint.mdAndDown || fullscreen"
    hide-overlay
    transition="dialog-bottom-transition"
    @keydown.esc="keyPressed"
  >
    <v-card class="pa-0">
      <slot name="title">
        <v-card-title
          v-if="title"
          :class="{
            'text-center': $vuetify.breakpoint.smAndDown,
            'justify-center': $vuetify.breakpoint.smAndDown,
            'justify-space-between': $vuetify.breakpoint.mdAndUp,
            'pt-0': true,
            'text-h6': $vuetify.breakpoint.smAndDown,
          }"
        >
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" class="pb-0">
            <v-row align="center" justify="space-between" no-gutters>
              <h3 class="heading-2 title--text capitalize-fl">
                {{ title }}
              </h3>
              <v-btn small color="info" fab @click="keyPressed">
                <v-icon normal color="white">mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-col>
          <v-col v-else cols="12">
            <h3 class="heading-2 title--text capitalize-fl">
              {{ title }}
            </h3>
            <v-row align="center" justify="center" no-gutters>
              <v-btn small color="info" fab @click="keyPressed">
                <v-icon normal color="white">mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-card-title>
        <v-divider v-if="showTitleSeparator" class="elevation-3" />
      </slot>
      <div :style="`height: calc(${maxHeight} - ${MODAL_HEIGHTS.TITLE}px)`">
        <slot />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {closeModal, maxHeightModal, MODAL_HEIGHTS} from '@/utils/modal';
export default {
  name: 'baseDialog',
  props: {
    title: {
      type: String,
      default: undefined,
    },
    showTitleSeparator: {
      type: Boolean,
      default: false,
    },
    dynamicHeight: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {MODAL_HEIGHTS};
  },
  provide() {
    return {
      dynamicHeight: this.dynamicHeight,
    };
  },
  computed: {
    maxHeight: maxHeightModal,
  },
  methods: {
    closeModal,
    keyPressed() {
      this.$router.push({name: 'Stock'});
      window.dispatchEvent(new CustomEvent('closeModal'));
    },
  },
};
</script>
