<template>
  <v-col cols="12" xl="4" lg="4" md="6" sm="6">
    <v-row align="center" justify="center" no-gutters>
      <v-card width="380">
        <v-img :src="trabajador['Foto Url']" width="390" height="390">
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <h3 class="title--text text-center my-2">{{ trabajador.nombre }}</h3>
        <v-row align="center" justify="space-around" no-gutters class="pb-2">
        <span v-for="item of trabajador.cargo"
              :key="item" class="body-1 title--text font-weight-regular ma-0">
          {{ item }}
        </span>
        </v-row>
      </v-card>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'trabajador',
  props: {
    trabajador: {
      type: Object,
      required: true,
    },
  },
};
</script>
