<template>
  <v-card flat style="background-color: white">
    <vehiculo-display
      v-for="(vehiculo, index) of vehiculos"
      v-model="vehiculoSelected"
      :key="index"
      :vehiculo="vehiculo"
      :detail="detail"
    >
    </vehiculo-display>
    <vehiculo-detalle
      v-if="isVehiculoSelected"
      :vehiculo="vehicle"
      :open="open"
    ></vehiculo-detalle>
  </v-card>
</template>

<script>
import VehiculoDisplay from '@/components/vehiculo/vehiculoDisplay';
import VehiculoDetalle from '@/components/vehiculo/vehiculoDetalle';
import {mapMutations, mapState} from 'vuex';

export default {
  name: 'vehiculoContainer',
  components: {VehiculoDetalle, VehiculoDisplay},
  props: {
    vehiculos: {
      type: Array,
      default: () => {
        return [];
      },
    },
    detail: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    vehicle: {},
    vehiculoSelected: {},
    open: false,
  }),
  computed: {
    ...mapState(['stock', 'stock2', 'carros', 'stockGeneral']),
    isVehiculoSelected() {
      // eslint-disable-next-line no-prototype-builtins
      return this.vehicle && this.vehicle.hasOwnProperty('id');
    },
  },
  watch: {
    stockGeneral(val) {
      if (val.length !== 0) {
        this.openVehiculo(this.idToOpen);
      }
    },
    vehiculoSelected(val) {
      if (!val.id) return;
      this.vehicle = this.stockGeneral.filter(
        (vehiculo) => vehiculo.id === val.id
      )[0];
      this.open = true;
      if (
        this.$router.history.current.name !== 'resultado-vehiculo' &&
        this.$router.history.current.name !== 'petersen'
      ) {
        this.$router.push({
          name: 'resultado-vehiculo',
          params: {
            vehiculoId: this.vehicle.id,
          },
        });
      }
    },
  },
  created() {
    window.addEventListener('closeModal', this.closeModal, false);
  },

  beforeDestroy() {
    window.removeEventListener('closeModal', this.closeModal, false);
  },
  mounted() {
    const id = this.$router.history.current.params.vehiculoId;
    if (id) {
      this.idToOpen = id;
      this.openVehiculo(id);
    }
  },
  methods: {
    ...mapMutations(['SET_VEHICULO']),
    precioFix(val) {
      return '$' + parseInt(val).toLocaleString('de-DE');
    },
    closeModal() {
      this.open = false;
      this.vehiculoSelected = {};
    },
    message(val) {
      let item = `https://wa.me/56944436444?text=Hola%20Petersen,%20vi%20en%20su%20web%20este%20(${val})%20y%20me%20gustaría%20tener%20mas%20información`;
      window.open(item, '_blank');
    },
    openVehiculo(id) {
      if (this.stockGeneral.length === 0 || !id) return;
      this.vehicle = this.stockGeneral.filter(
        (vehiculo) => vehiculo.id === id
      )[0];

      this.open = true;
      if (
        this.$router.history.current.name !== 'resultado-vehiculo' &&
        this.$router.history.current.name !== 'petersen'
      ) {
        this.$router.push({
          name: 'resultado-vehiculo',
          params: {
            vehiculoId: this.vehicle.id,
          },
        });
      }
    },
  },
};
</script>
