<template>
  <div>
    <v-row align="center" justify="center" no-gutters>
      <v-progress-circular v-if="loading" indeterminate color="info" />
    </v-row>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'GlobalLoading',
  computed: {
    ...mapState(['loading']),
  },
};
</script>

<style scoped></style>
