<template>
  <div>
    <v-row align="center" justify="center" no-gutters>
      <v-slide-group
          v-if="$vuetify.breakpoint.smAndUp"
          v-model="model"
          :value="0"
          mandatory
          show-arrows
      >
        <template v-slot:next>
          <v-btn color="info" small fab>
            <v-icon normal color="white">mdi-arrow-right</v-icon>
          </v-btn>
        </template>
        <template v-slot:prev>
          <v-btn color="info" small fab>
            <v-icon normal color="white">mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <v-slide-item
            v-for="(vehiculos, index) of stock"
            :key="index"
            class="pl-3 pr-3"
            v-slot:default="{}"
        >
          <vehiculo-container
              :vehiculos="vehiculos"
              class="mt-2"
          ></vehiculo-container>
        </v-slide-item>
      </v-slide-group>
      <v-carousel v-else v-model="model" hide-delimiters height="580" light
      >
        <v-carousel-item v-for="(vehiculos, index) of stock"
                         :key="index"
                         class="px-3"
                         v-slot:default="{}">
          <vehiculo-container
              :vehiculos="vehiculos"
              class="my-2"
          ></vehiculo-container>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <global-loading/>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import GlobalLoading from '@/components/core/GlobalLoading';
import VehiculoContainer from '@/components/vehiculo/vehiculoContainer';

export default {
  name: 'sliderVehicles',
  components: {VehiculoContainer, GlobalLoading},
  data: () => ({
    model: 0,
  }),
  computed: {
    ...mapState(['stock', 'loading', 'stock2']),
    newStock() {
      const vehicles = [];
      this.stock2.forEach((stock) => {
        vehicles.push([stock]);
      });

      return vehicles;
    },
  },
};
</script>