<template>
  <div style="background-color: white">
    <v-layout v-if="vehicle && vehicle.id" justify-center column>
      <div>
        <v-img
          v-if="!isVid(imagesFixes(vehicle.datos)[model])"
          contain
          :src="validateImageFull(imagesFixes(vehicle.datos)[model])"
          :lazy-src="validateImageSmall(imagesFixes(vehicle.datos)[model])"
          alt="petersen vehiculo"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="info"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <video-component
          v-else
          :key="imagesFixes(vehicle.datos)[model].url"
          :source="imagesFixes(vehicle.datos)[model]"
        />
      </div>
      <v-slide-group v-model="model" :value="0" mandatory center-active>
        <v-slide-item
          v-for="(items, i) in imagesFixes(vehicle.datos)"
          :key="i"
          v-slot:default="{active, toggle}"
        >
          <div style="width: 85px; height: 55px">
            <img
              v-if="!isVid(items)"
              content
              width="85"
              height="55"
              style="border-radius: 5px"
              alt="petersen vehiculo"
              :src="validateImageLarge(items)"
              class="ma-1"
              @click="toggle"
            />
            <v-img
              v-else
              :src="validateImageLarge(imagesFixes(vehicle.datos)[3])"
              :lazy-src="validateImageSmall(imagesFixes(vehicle.datos)[3])"
              class="align-start text-start"
              @click="toggle"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="info"
                  ></v-progress-circular>
                </v-row>
              </template>
              <v-chip color="info" small class="white--text mt-1 ml-1"
                >Video
              </v-chip>
            </v-img>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-layout>
  </div>
</template>

<script>
import {
  validateImageLarge,
  validateImageSmall,
  validateImageFull,
  imagesFixes,
} from '@/utils/vehiculo';
import VideoComponent from '@/components/vehiculo/videoComponent.vue';

export default {
  name: 'sliderCars',
  components: {VideoComponent},
  props: {
    item: {
      type: Object,
    },
    sizeVehicle: {
      type: String,
    },
    sizeItem: {
      type: String,
    },
    sizeImage: {
      type: String,
    },
  },
  data: function () {
    return {
      model: 0,
      typeOf: typeof this.item,
      type: typeof this.sizeVehicle,
      typeItem: typeof this.sizeItem,
      typeImg: typeof this.sizeImage,
      vehicle: {},
    };
  },
  mounted() {
    this.vehicle = this.item;
  },
  methods: {
    validateImageLarge,
    validateImageSmall,
    validateImageFull,
    imagesFixes,
    isVid(file) {
      return file.url.includes('mp4');
    },
  },
};
</script>
