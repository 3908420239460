<template>
  <div v-if="source" class="video-container">
    <v-img
      v-if="!playing && photo"
      :src="photo.url"
      class="video-cover"
      @click="togglePlayPause"
    ></v-img>

    <v-progress-circular
      v-if="loading"
      indeterminate
      color="info"
      class="loading"
    ></v-progress-circular>

    <v-fab-transition>
      <v-btn
        v-if="!playing"
        class="play-fab"
        color="primary"
        fab
        @click="togglePlayPause"
      >
        <v-icon color="info" x-large>mdi-play</v-icon>
      </v-btn>
    </v-fab-transition>

    <video
      class="video-player rounded elevation-3"
      ref="videoPlayer"
      @loadedmetadata="handleLoadedMetadata"
      @play="playing = true"
      @pause="playing = false"
      @click="togglePlayPause"
      :preload="preload"
      :muted="muted"
      :volume="volume"
    >
      <source :src="source.url" type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>

    <div class="controls" v-if="playing">
      <v-btn icon @click="toggleMute">
        <v-icon color="white"
          >{{ muted ? 'mdi-volume-off' : 'mdi-volume-high' }}
        </v-icon>
      </v-btn>
      <v-slider
        v-model="volume"
        :disabled="muted"
        step="0.1"
        min="0"
        max="1"
        hide-details
        class="volume-slider"
        @input="updateVolume"
      ></v-slider>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      required: true,
    },
    photo: {
      type: Object,
      required: false,
    },
    preload: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      loading: true,
      playing: false,
      muted: false,
      volume: 1,
    };
  },
  methods: {
    togglePlayPause() {
      const videoPlayer = this.$refs.videoPlayer;
      if (this.playing) {
        videoPlayer.pause();
      } else {
        videoPlayer.play();
      }
    },
    handleLoadedMetadata() {
      this.loading = false;
    },
    toggleMute() {
      this.muted = !this.muted;
    },
    updateVolume() {
      this.$refs.videoPlayer.volume = this.volume;
    },
  },
};
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
}

.video-player {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.play-fab {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.controls {
  position: absolute;
  bottom: 5px;
  left: 5px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 8px;
  z-index: 2;
}

.volume-slider {
  width: 100px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
