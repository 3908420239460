<template>
  <v-container>
    <v-card
        flat
        color="#eff0f2"
        :max-height="$vuetify.breakpoint.mdAndUp ? 250 : 400"
        :height="$vuetify.breakpoint.mdAndUp ? 220 : 280"
    >
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12">
          <v-row align="center" justify="center" no-gutters>
            <h1 class="info--text text-center">Conversemos en persona</h1>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" justify="center" no-gutters>
            <h3 class="info--text">Horario de atención</h3>
          </v-row>
        </v-col>
        <v-col cols="12" v-for="(horario, index) of horarios" :key="index">
          <v-row align="center" justify="center" no-gutters>
            <h4 class="info--text font-weight-light">{{ horario }}</h4>
          </v-row>
        </v-col>
      </v-row>
      <social-buttons margin></social-buttons>
      <div class="mb-12"></div>
    </v-card>
  </v-container>
</template>

<script>
import SocialButtons from '@/components/core/socialButtons';

export default {
  name: 'hablemosPersonas',
  components: {SocialButtons},
  data: () => ({
    horarios: [
      'Lunes - Viernes: 9 a. m. - 6 p. m.',
      'Sabado: 10 a. m. - 2 p. m.',
      'Domingo: Solo atención online',
    ],
  }),
};
</script>

<style scoped></style>
